import React, {useEffect} from "react"
import {Link, useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { hotjar } from 'react-hotjar'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "../styles/parts/home.sass"
// Part1
import analysis from "../icons/home/part1-s.svg"
// Cities
import shanghai from "../icons/home/shanghai.svg"
import singapore from "../icons/home/singapore.svg"
import japan from "../icons/home/japan.svg"
// Wrapper
import overallUnderline from "../icons/home/overall_underline.svg"
import valueUnderline from "../icons/home/bestvalue_underline.svg"
import fastUnderline from "../icons/home/fastest_underline.svg"
// about us
import scientific from "../icons/home/scientific.svg"
import team from "../icons/home/team.svg"
import realtest from "../icons/home/realtest.svg"
import independent from "../icons/home/independent.svg"

const Home = () => {
    useEffect(() => {
        // Handler to call
        function initiateHotjar() {
          hotjar.initialize(1995458, 6);
        }
        // Add event listener
        window.addEventListener("scroll", initiateHotjar, {once: true});
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", initiateHotjar);
    }, []);
    const data = useStaticQuery(graphql`
      query {
        out: allReferJson(sort: {order: ASC, fields: rank_out}){
          edges {
            node {
                vpn
                name
                rank_out
                url
                logo{
                    childImageSharp{
                        fluid(maxWidth: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                home{
                  tag1
                  tag2
                  underline
                }
            }
          }
        }
        in: allReferJson(sort: {order: ASC, fields: rank_in}){
            edges {
              node {
                vpn
                name
                rank_in
                url
                logo{
                    childImageSharp{
                        fluid(maxWidth: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                home{
                  tag1
                  tag2
                  underline
                }
              }
            }
          }
      }
    `)
    const underlines = [overallUnderline,valueUnderline,fastUnderline]
    const vpnOut = data.out.edges.slice(0,4)
    var vpnIns = data.in.edges.filter((vpn)=> {
        const home = vpn.node.home
        return home !== null && vpn.node.rank_in !== null 
      })
    vpnIns.sort((a,b)=> a.node.rank_in > b.node.rank_in ? 1 : -1)
    const vpnIn = vpnIns.slice(0,3)
    return (
        <Layout title="最专业【VPN翻墙、科学上网】评测 - 首页｜翻墙101">
            <div id = "home">
                <div id="part1" className="parts">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-1" />
                            <div className="col">
                                <h1><span>我们帮助用户</span><span>"翻越"那堵墙</span></h1>
                                <p className="below" >通过云服务器的大数据分析</p>
                                <p className="below" >我们帮助你找到最合适自己的翻墙工具</p>
                                <img loading="eager" id="main1" src={analysis} alt="翻墙" />
                                <h2>查看工具分类</h2>
                                <ol id="categories">
                                    <li className="cards"><Link to="/fanqiang-test/" ><div className="item">
                                        <p className="icon"><span role="img" aria-label="速度" >🚀&nbsp;&nbsp;</span></p>
                                        <p className="text">最新测速报告</p>
                                    </div></Link></li>
                                    <li className="cards"><Link to="/vpn-back-china/" ><div className="item">
                                        <p className="icon"><span role="img" aria-label="国旗" >🇨🇳&nbsp;&nbsp;</span></p>
                                        <p className="text">回国 VPN</p>
                                    </div></Link></li>
                                    <li className="cards"><Link to="/fanqiang-hou/" ><div className="item">
                                        <p className="icon"><span role="img" aria-label="机器人" >🤖&nbsp;&nbsp;</span></p>
                                        <p className="text">翻墙后</p>
                                    </div></Link></li>
                                    <li className="cards"><Link to="/guides/" ><div className="item">
                                        <p className="icon"><span role="img" aria-label="查阅指南" >📋&nbsp;&nbsp;</span></p>
                                        <p className="text">翻墙指南</p>
                                    </div></Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="part2" className="parts" >
                    <h2>我们的大数据测试</h2>
                    <div id="data-row">
                        <div className="cities">
                            <div className="city">
                                <img loading="lazy" src={shanghai} alt="上海" />
                            </div>
                            <h3>7</h3>
                            <p>城市阿里云翻墙实测</p>
                        </div>
                        <div className="cities">
                            <div className="city">
                                <img loading="lazy" src={singapore} alt="新加坡"/>
                            </div>
                            <h3>8</h3>
                            <p>国家AWS节点回国测速</p>
                        </div>
                        <div className="cities">
                            <div className="city">
                                <img loading="lazy" src={japan} alt="日本"/>
                            </div>
                            <h3>4000+</h3>
                            <p>小时全球云资源消耗</p>
                        </div>
                    </div>
                </div>
                <div id="part3" className="parts" >
                    <div id="vpns" >
                        <div id="out" className="recommend" >
                            <h3>翻墙首选VPN</h3>
                            <div className="list row">
                                {vpnOut.map((vpn)=>(
                                    <div className="best-vpns">
                                        <p className="best" >{vpn.node.home.tag1}</p>
                                        <p className="description" >{vpn.node.home.tag2}</p>
                                        <p className="underline"><img src={underlines[vpn.node.home.underline]} alt="下划线" /></p>
                                        <OutboundLink className="cards" href={vpn.node.url} 
                                            target="_blank" rel="noreferrer nofollow" >
                                            <div className="logo">
                                                <div className="vpn-logo">
                                                    <Img fluid={vpn.node.logo.childImageSharp.fluid} alt={vpn.node.name+" logo"} />
                                                </div>
                                            </div>
                                        </OutboundLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div id="in" className="recommend" >
                            <h3>回国首选VPN</h3>
                            <div className="list row">
                                {vpnIn.map((vpn)=>(
                                    <div className="best-vpns">
                                        <p className="best" >{vpn.node.home.tag1}</p>
                                        <p className="description" >{vpn.node.home.tag2}</p>
                                        <p className="underline"><img src={underlines[vpn.node.home.underline]} alt="下划线" /></p>
                                        <OutboundLink className="cards" href={vpn.node.url} 
                                            target="_blank" rel="noreferrer nofollow" >
                                            <div className="logo">
                                                <div className="vpn-logo">
                                                    <Img fluid={vpn.node.logo.childImageSharp.fluid} alt={vpn.node.name+" logo"} />
                                                </div>
                                            </div>
                                        </OutboundLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="part4" className="parts" >
                    <h2>我们的测评</h2>
                    <div className="container content">
                        <div className="row" id="team">
                            <div className="col-12 col-md-6">
                                <h3>技术背景的团队</h3>
                                <p>我们的技术人员都具有FLAG（Facebook, LinkedIn, Apple, Google）以及同级别科技公司的工作经验。</p>
                                <p>不同于众多良莠不齐的评测平台，我们的测试人员能够从自身的从业的角度给出更专业，更科学的VPN建议</p>
                                <Link class="btn about-link" role="button" to="/about/us/" >查看我们的团队</Link>
                            </div>
                            <div className="col-12 col-md-5 images">
                                <img loading="lazy" src={team} alt="科技背景的成员"/>
                            </div>
                        </div>
                        <div className="row left" >
                            <div className="col-12" />
                            <div className="col-12 col-md-5 images">
                                <img loading="lazy" src={realtest} alt="实地测速"/>
                            </div>
                            <div className="col-12 col-md-6">
                                <h3>服务器实测</h3>
                                <p>我们通过租用阿里云与腾讯云在中国境内的服务器来测试翻墙VPN的可靠度与速度。相应的，我们也通过租用AWS（亚马逊云）全球节点的服务器测试翻墙回国VPN的速度。</p>
                                <p>所有数据皆为实测的结果，我们不会作出任何的修正。</p>
                                <Link class="btn about-link" role="button" to="/about/speedtest/" >查看我们的测速方式</Link>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-12 col-md-6">
                                <h3>科学的测试标准</h3>
                                <p>通过我们在科技行业工作的经历和自身技术背景，我们制定了科学衡量VPN性能的标准。</p>
                                <p>不同于一般评测对于速度的单方面追求，我们综合考量加密算法的安全性已经软件的稳定度等，给出更为全面的评测意见。</p>
                                <Link class="btn about-link" role="button" to="/about/review/" >查看我们的测评标准</Link>
                            </div>
                            <div className="col-12 col-md-5 images">
                                <img loading="lazy" src={scientific} alt="科学综合的测评体系"/>
                            </div>
                        </div>
                        <div className="row left" >
                            <div className="col-12 col-md-5 images">
                                <img loading="lazy" src={independent} alt="独立的评测"/>
                            </div>
                            <div className="col-12 col-md-6">
                                <h3>独立的测评</h3>
                                <p>作为独立的测评团体，我们不会因为任何厂商的要求更改我们的测试结果。我们承诺会一直采用客观中立的态度来进行评测。</p>
                                <p>我们只会推荐自己愿意去使用的产品，我们不推荐垃圾。</p>
                                <Link class="btn about-link" role="button" to="/about/statement/" >查看我们的测评宣言</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home